
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import moment from 'moment';
import Route from '../components/Route.vue';
import { useStore } from 'vuex';
import { ROUTE_ID } from '@/enums';
import { handleError, loadStop } from '@/utils';
import { useRoute } from 'vue-router';

let loadId: number;
let timeId: number;

export default defineComponent({
  name: 'Stop',
  components: { Route },

  setup() {
    const store = useStore();
    const route = useRoute();
    const currentDate = ref();
    const currentTime = ref();
    const getBricktownDurations = computed(() => store.getters.getBricktownDurations);
    const getDowntownDurations = computed(() => store.getters.getDowntownDurations);
    const getStop = computed(() => store.getters.getStop);

    const getStopDurationData = (stopId: number, routeId: number) => {
      if (routeId === ROUTE_ID.BRICKTOWN) {
        return getBricktownDurations.value[stopId];
      } else if (routeId === ROUTE_ID.DOWNTOWN) {
        return getDowntownDurations.value[stopId];
      }
      return null;
    };

    const load = async () => {
      try {
        await store.dispatch(loadStop, route?.params?.slug || '');
      } catch (err) {
        // handle error
        handleError(err);
      }
    };

    const updateTime = async () => {
      currentDate.value = moment().format('MMMM D, YYYY');
      currentTime.value = moment().format('h:mm a');
    };

    onBeforeMount(async () => {
      updateTime();
      timeId = setInterval(updateTime, 1000 * 2); // update time every 2 seconds
    });

    onMounted(async () => {
      await load();
    });

    onBeforeUnmount(() => {
      clearInterval(loadId);
    });

    onUnmounted(() => {
      clearInterval(timeId);
    });
    return {
      currentDate,
      currentTime,
      getStopDurationData,
      getStop,
    };
  },
});
