import { SSEConfig, SSEConnection } from "@/types";
import { formatters } from "./formatter";

export const serverSentEvent = async (
  url: string,
  cfg: SSEConfig
): Promise<SSEConnection> => {
  const config = Object.assign(
    {},
    {
      withCredentials: false,
      format: "plain"
    },
    cfg
  );

  const source = new EventSource(url, {
    withCredentials: config.withCredentials
  });

  return new Promise((resolve, reject) => {
    source.onerror = reject;
    source.onopen = () => {
      source.onerror = null;
      const subscribers: { [key: string]: any[] } = {};

      resolve({
        getSource() {
          return source;
        },
        onError(handler: (this: EventSource, ev: Event) => void) {
          source.onerror = handler;

          return this;
        },
        subscribe(event: "" | "error" | "message" | "open", handler: any) {
          const listener = (e: any) => {
            let data;

            try {
              data = formatters[config.format](e);
            } catch (err) {
              if (typeof source.onerror === "function") {
                source.onerror(err);
              }
            }

            handler(data, e);
          };

          if (!subscribers[event]) {
            subscribers[event] = [];
          }

          subscribers[event].push(listener);

          if (event === "") {
            // Catches messages without any event specified
            source.onmessage = listener;
          } else {
            source.addEventListener(event, listener);
          }

          return this;
        },
        unsubscribe(event: any) {
          if (event === "") {
            source.onmessage = null;

            return this;
          }

          // Check if there are any subscribers for this event
          if (!subscribers[event]) {
            return this;
          }

          subscribers[event].forEach(listener => {
            source.removeEventListener(event, listener);
          });

          subscribers[event] = [];

          return this;
        },
        close() {
          source.close();

          // Make sure listeners are cleared (nobody likes mem leaks, right?)
          Object.keys(subscribers).forEach(event => {
            subscribers[event] = [];
          });
        }
      });
    };
  });
};
