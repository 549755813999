import { ActionContext } from 'vuex';
import axios from 'axios';
import {
  CannedMessage,
  CannedMessageDate,
  CannedMessageResponse,
  Duration,
  MessageSwitch,
  State,
  StopRoute,
  StopStation,
} from '@/types';
import {
  ALL_STOPS_API,
  CANNED_MESSAGES_API,
  futureDate,
  handleError,
  LOAD_STOP,
  LOAD_STOPS,
  serverSentEvent,
  SUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM,
  unSubscribeToUsfleetStopsDataStream,
  UNSUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM,
  updateHashedStop,
  UPDATE_BRICKTOWN_STOP_DURATION,
  UPDATE_DOWNTOWN_STOP_DURATION,
  UPDATE_HASHED_STOP,
  UPDATE_STOP_MESSAGE,
  UPDATE_STOPS_MESSAGE,
  updateStopsMessages,
  US_FLEET_ETA,
  stopAndRouteCannedMessages,
  specificRouteMessages,
  LOAD_CUSTOM_DISPLAY_MESSAGES,
  updateSponsorName,
  UPDATE_SPONSOR_NAME,
} from '@/utils';
import { ROUTE_ID } from '@/enums';

export const actions = {
  checkCannedMessages: async ({ commit, dispatch, state }: ActionContext<State, State>) => {
    try {
      const response = await axios.get(CANNED_MESSAGES_API);
      const messageResponseData: CannedMessageResponse[] = response?.data?.data || [];

      // grab the switch messages
      const messageSwitch: MessageSwitch | undefined = messageResponseData?.[0]?.messageSwitch;
      if (messageSwitch?.status) {
        commit(LOAD_CUSTOM_DISPLAY_MESSAGES, {
          status: true,
          customBricktownMessage: messageSwitch?.customBricktownMessage || '',
          customDowntownMessage: messageSwitch?.customDowntownMessage || '',
        });
      } else {
        commit(LOAD_CUSTOM_DISPLAY_MESSAGES, {
          status: false,
          customBricktownMessage: '',
          customDowntownMessage: '',
        });
      }

      commit(UPDATE_STOPS_MESSAGE, '');

      const [routes, stops] = stopAndRouteCannedMessages(messageResponseData);

      const [bricktownRoutes, downtownRoutes] = specificRouteMessages(routes);

      // Write route messages
      // Downtown first, bricktown overwrites down down
      const downtownMessage = downtownRoutes?.cannedMessage;

      // if downtown, update all stops
      if (downtownMessage) {
        // all stops
        dispatch(updateStopsMessages, downtownMessage);
      }

      const bricktownMessage = bricktownRoutes?.cannedMessage;

      // if bricktown, update all bricktown, overwrite downtown
      if (bricktownMessage) {
        // bircktown stop stops.
        state.stops
          ?.filter((stop: StopStation) => {
            if (stop?.routes) {
              if (stop?.routes) {
                const routeIds = stop.routes.map((route: StopRoute) => route.routeId);
                return routeIds.includes(ROUTE_ID.BRICKTOWN);
              }
            }
          })
          .forEach((stop: StopStation) =>
            commit(UPDATE_STOP_MESSAGE, {
              stopId: stop.stopId,
              message: bricktownMessage,
            })
          );
      }

      // Stops overwrite everything
      stops.forEach((stopMessage: CannedMessage) => {
        commit(UPDATE_STOP_MESSAGE, {
          stopId: stopMessage.stopId,
          message: stopMessage.cannedMessage,
        });
      });
    } catch (err) {
      handleError(err);
    }
  },
  loadStop: async ({ commit }: ActionContext<State, State>, slug: string) => {
    try {
      const response = await axios.get(ALL_STOPS_API);
      const { data } = response;
      const stop = data?.data?.find((stop: StopStation) => stop.slug === slug);
      commit(LOAD_STOP, stop); // load a stop
    } catch (err) {
      handleError(err);
    }
  },
  loadStops: async ({ commit, dispatch, state }: ActionContext<State, State>) => {
    try {
      const response = await axios.get(ALL_STOPS_API);

      const { data } = response;

      const rawData = response?.data?.data || [];

      if (state?.stop?.stopId) {
        const sponsorName = rawData.find((raw: StopStation) => raw.stopId === state?.stop?.stopId)
          ?.sponsorName;
        if (!sponsorName || state.stop.sponsorName !== sponsorName) {
          dispatch(updateSponsorName, sponsorName);
        }
      }

      const stops =
        rawData.sort((stopA: StopStation, stopB: StopStation) => {
          if (stopA?.stopName && stopB?.stopName) {
            return stopA.stopName > stopB.stopName ? 1 : -1;
          }
          return 0;
        }) || [];

      commit(LOAD_STOPS, stops); // load stops
      // dispatch(updateStopsMessages, statusText);

      // for each stop, hash it
      stops?.forEach((stop: StopStation) => {
        dispatch(updateHashedStop, stop);
      });
    } catch (err) {
      handleError(err);
    }
  },
  subscribeToUsfleetStopsDataStream: async ({
    commit,
    dispatch,
    state,
  }: ActionContext<State, State>) => {
    try {
      const stopsConnection = await serverSentEvent(US_FLEET_ETA, {
        format: 'json',
      }); // omit for no format pre-processing

      // Catch any errors (ie. lost connections, etc.)
      //eslint-disable-next-line
      stopsConnection.onError((e: any) => {
        //eslint-disable-next-line
        console.error('lost stops connection; giving up!', e);

        // This is purely for example; EventSource will automatically
        // attempt to reconnect indefinitely, with no action needed
        // on your part to resubscribe to events once (if) reconnected
        stopsConnection.close();
        dispatch(unSubscribeToUsfleetStopsDataStream);
      });

      // Listen for messages without a specified event
      //eslint-disable-next-line
      stopsConnection.subscribe('', async (data: any) => {
        const foundStop = state?.stops?.find((stop: StopStation) => stop.stopId === data.stopId);

        if (foundStop) {
          const stopDurations: Duration = {
            durations: [data.firstDuration, data.secondDuration, data.thirdDuration],
            routeName: data.routeName,
            routeId: data.routeId,
            stopName: data.stopName,
            stopId: data.stopId,
          };

          // New way, puts it in the state
          if (data.routeId === ROUTE_ID.BRICKTOWN) {
            commit(UPDATE_BRICKTOWN_STOP_DURATION, stopDurations);
          } else if (data.routeId === ROUTE_ID.DOWNTOWN) {
            commit(UPDATE_DOWNTOWN_STOP_DURATION, stopDurations);
          }
        }
      });

      commit(SUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM, stopsConnection);
    } catch (err) {
      handleError(err);
      dispatch(unSubscribeToUsfleetStopsDataStream);
    }
  },
  unSubscribeToUsfleetStopsDataStream: async ({ commit, state }: ActionContext<State, State>) => {
    try {
      if (state.usFleetStopsSubscription != null) {
        console.log('unsubscirbe data stream');
        state.usFleetStopsSubscription.close();
        commit(UNSUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM);
      }
    } catch (err) {
      handleError(err);
    }
  },
  updateHashedStop: async ({ commit }: ActionContext<State, State>, stop: StopStation) => {
    commit(UPDATE_HASHED_STOP, stop);
  },
  updateSponsorName: async ({ commit }: ActionContext<State, State>, sponsorName: string) => {
    commit(UPDATE_SPONSOR_NAME, sponsorName);
  },
  updateStopsMessages: async ({ commit }: ActionContext<State, State>, message: string) => {
    commit(UPDATE_STOPS_MESSAGE, message);
  },
};
