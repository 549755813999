export const LOAD_CUSTOM_DISPLAY_MESSAGES = 'LOAD_CUSTOM_DISPLAY_MESSAGES';
export const LOAD_STOP = 'LOAD_STOP';
export const LOAD_STOPS = 'LOAD_STOPS';
export const SUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM = 'SUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM';
export const UNSUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM = 'UNSUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM';
export const UPDATE_BRICKTOWN_STOP_DURATION = 'UPDATE_BRICKTOWN_STOP_DURATION';
export const UPDATE_DOWNTOWN_STOP_DURATION = 'UPDATE_DOWNTOWN_STOP_DURATION';
export const UPDATE_HASHED_STOP = 'UPDATE_HASHED_STOP';
export const UPDATE_STOP_MESSAGE = 'UPDATE_STOP_MESSAGE';
export const UPDATE_STOPS_MESSAGE = 'UPDATE_STOPS_MESSAGE';
export const UPDATE_SPONSOR_NAME = 'UPDATE_SPONSOR_NAME';
