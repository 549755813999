
import { Duration } from "@/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "Route",
  props: {
    routeId: Number,
    routeDurations: Object as PropType<Duration>,
    table: {
      type: Boolean,
      default: false
    }
  }
});
