import { State } from '@/types';

export const state: State = {
  alertMessage: '',
  assetData: [],
  bricktownDurations: {},
  customBricktownMessage: '',
  customDowntownMessage: '',
  customMessage: false,
  downtownDurations: {},
  stop: undefined,
  stops: [],
  stopsHash: {},
  usFleetStopsSubscription: undefined,
};
