import { Duration, MessageSwitch, State, StopStation, Subscription } from '@/types';

export const mutations = {
  LOAD_CUSTOM_DISPLAY_MESSAGES: (
    state: State,
    { customBricktownMessage, customDowntownMessage, status }: MessageSwitch
  ) => {
    state.customBricktownMessage = customBricktownMessage;
    state.customDowntownMessage = customDowntownMessage;
    state.customMessage = status;
  },
  LOAD_STOP: (state: State, stop: StopStation) => {
    state.stop = stop;
  },
  LOAD_STOPS: (state: State, stops: StopStation[]) => {
    state.stops = stops;
  },
  SUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM: (state: State, sseEventConnection: Subscription) => {
    state.usFleetStopsSubscription = sseEventConnection;
  },
  UNSUBSCRIBE_TO_USFLEET_STOPS_DATA_STREAM: (state: State) => {
    state.usFleetStopsSubscription = undefined;
  },
  UPDATE_BRICKTOWN_STOP_DURATION: (state: State, bricktownStopDuration: Duration) => {
    if (bricktownStopDuration?.stopId) {
      state.bricktownDurations[bricktownStopDuration?.stopId] = bricktownStopDuration;
    }
  },
  UPDATE_DOWNTOWN_STOP_DURATION: (state: State, downtownStopDuration: Duration) => {
    if (downtownStopDuration?.stopId) {
      state.downtownDurations[downtownStopDuration?.stopId] = downtownStopDuration;
    }
  },
  UPDATE_HASHED_STOP: (state: State, stop: StopStation) => {
    if (stop?.stopId) {
      state.stopsHash[stop.stopId] = stop;
    }
  },
  UPDATE_SPONSOR_NAME: (state: State, sponsor: string) => {
    if (state.stop) {
      state.stop.sponsorName = sponsor;
    }
  },
  UPDATE_STOP_MESSAGE: (state: State, { stopId, message }: { stopId: number; message: string }) => {
    const foundStop = state.stops?.find((stop: StopStation) => stop.stopId == stopId);
    if (foundStop) {
      foundStop.alertMessage = message;
      if (state.stop?.stopId === foundStop.stopId) {
        state.stop.alertMessage = message;
      }
    }
  },
  UPDATE_STOPS_MESSAGE: (state: State, message: string) => {
    state.alertMessage = message;
    state?.stops?.forEach((stop: StopStation) => {
      if (state.stop) {
        state.stop.alertMessage = message;
      }
      stop.alertMessage = message;
    });
  },
};
