import { futureDate } from './future-date';
import { pastDate } from './past-date';

export const validDate = (start?: string, end?: string) => {
  if (futureDate(end)) {
    if (!start) {
      return true;
    }
    return pastDate(start);
  }
  return false;
};
