<template>
  <div v-if="isDowntown && customDowntownMessage !== ''">
    <div class="route_name">{{ routeName }}</div>
    <div class="arrival-times-wrapper">
      <span class="custom-message">{{ customDowntownMessage }}</span>
    </div>
  </div>
  <div v-else-if="isBricktown && customBricktownMessage !== ''">
    <div class="route_name">{{ routeName }}</div>
    <div class="arrival-times-wrapper">
      <div class="custom-message">{{ customBricktownMessage }}</div>
    </div>
  </div>
  <div v-else-if="stopDurationData?.durations?.length > 0">
    <div class="route_name">{{ routeName }}</div>
    <div class="arrival-times-wrapper">
      <div class="arrival" v-for="(dur, id) in stopDurationData.durations" :key="id">
        {{ dur }} min
      </div>
    </div>
  </div>
  <div v-else>
    <div class="route_name">{{ routeName }}</div>
    <div class="arrival-times-wrapper">
      <div class="custom-message">No Service</div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { ROUTE_NAME } from '@/enums';
import { Duration } from '@/types';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Route',
  props: {
    routeId: Number,
    routeName: String,
    stopDurationData: Object as PropType<Duration>,
  },
  setup(props) {
    const store = useStore();
    const customBricktownMessage = computed(() => store.getters.getCustomBricktownMessage);
    const customDowntownMessage = computed(() => store.getters.getCustomDowntownMessage);
    const showCustomMessage = computed(() => store.getters.getCustomMessageSwitch);

    const isBricktown = ref(props.routeName?.includes(ROUTE_NAME.BRICKTOWN));
    const isDowntown = ref(props.routeName?.includes(ROUTE_NAME.DOWNTOWN));

    console.log('brick', customBricktownMessage);
    console.log('down', customDowntownMessage);

    return {
      customBricktownMessage,
      customDowntownMessage,
      showCustomMessage,
      isBricktown,
      isDowntown,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
