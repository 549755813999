<template>
  <div class="home"></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "Home"
});
</script>
<style scoped lang="scss"></style>
