import { CannedMessage } from "@/types";
import { validDate } from "./valid-date";

export const DateFilter = (message: CannedMessage) => {
  const startString =
    typeof message?.messageStart === "string"
      ? message?.messageStart
      : message?.messageStart?.date;
  const endString =
    typeof message?.messageEnd === "string"
      ? message?.messageEnd
      : message?.messageEnd?.date;
  return validDate(startString, endString);
};
