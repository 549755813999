<template>
  <div class="wrapper stop">
    <div class="stop_left">
      <div class="stop_header">
        <time
          ><div>{{ currentTime }}</div>
          <div>{{ currentDate }}</div></time
        >
        <h1 :data-stopId="getStop?.stopId" :class="{ 'single-route': getStop?.routes?.length < 2 }">
          {{ getStop?.stopName }}
        </h1>
        <p class="sponsor" v-if="getStop?.sponsorName">Sponsored by {{ getStop?.sponsorName }}</p>
        <p class="sponsor" v-else>{{ getStop?.sponsorDefaultMessage }}</p>
      </div>
      <div class="stop_routes">
        <div class="routes_header">Streetcar<br />Arrivals</div>
        <div class="routes">
          <div class="route" v-for="route in getStop?.routes" v-bind:key="route.routeId">
            <route
              class="route_arrivals"
              :routeId="route?.routeId"
              :stopDurationData="getStopDurationData(getStop?.stopId, route?.routeId)"
              :routeName="route?.routeName"
            ></route>
          </div>
        </div>
        <div class="reminders">
          <div class="reminder-disclaimer">
            <span>Arrival Times Are Estimates</span>
          </div>
          <div class="operated_by">
            <span>Operated by:</span> <img :src="getStop?.operatedByLogo" />
          </div>
          <div class="tracked_by">
            <span>Tracked by:</span> <img :src="getStop?.trackedByLogo" />
          </div>
        </div>
      </div>
    </div>
    <div class="stop_right">
      <div class="stop_alert" v-if="getStop?.alertMessage">
        <div class="stop_alert_header">Alert</div>
        <div class="stop_alert_body" v-if="getStop?.alertMessage">
          {{ getStop?.alertMessage }}
        </div>
      </div>
      <div class="stop_map">
        <iframe :src="getStop?.kioskMapUrl">
          The map is unavailable.
        </iframe>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import moment from 'moment';
import Route from '../components/Route.vue';
import { useStore } from 'vuex';
import { ROUTE_ID } from '@/enums';
import { handleError, loadStop } from '@/utils';
import { useRoute } from 'vue-router';

let loadId: number;
let timeId: number;

export default defineComponent({
  name: 'Stop',
  components: { Route },

  setup() {
    const store = useStore();
    const route = useRoute();
    const currentDate = ref();
    const currentTime = ref();
    const getBricktownDurations = computed(() => store.getters.getBricktownDurations);
    const getDowntownDurations = computed(() => store.getters.getDowntownDurations);
    const getStop = computed(() => store.getters.getStop);

    const getStopDurationData = (stopId: number, routeId: number) => {
      if (routeId === ROUTE_ID.BRICKTOWN) {
        return getBricktownDurations.value[stopId];
      } else if (routeId === ROUTE_ID.DOWNTOWN) {
        return getDowntownDurations.value[stopId];
      }
      return null;
    };

    const load = async () => {
      try {
        await store.dispatch(loadStop, route?.params?.slug || '');
      } catch (err) {
        // handle error
        handleError(err);
      }
    };

    const updateTime = async () => {
      currentDate.value = moment().format('MMMM D, YYYY');
      currentTime.value = moment().format('h:mm a');
    };

    onBeforeMount(async () => {
      updateTime();
      timeId = setInterval(updateTime, 1000 * 2); // update time every 2 seconds
    });

    onMounted(async () => {
      await load();
    });

    onBeforeUnmount(() => {
      clearInterval(loadId);
    });

    onUnmounted(() => {
      clearInterval(timeId);
    });
    return {
      currentDate,
      currentTime,
      getStopDurationData,
      getStop,
    };
  },
});
</script>
<style scoped lang="scss"></style>
