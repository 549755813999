<template>
  <router-view />
</template>
<script lang="ts">
import { computed, defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import {
  checkCannedMessages,
  handleError,
  loadStops,
  subscribeToUsfleetStopsDataStream,
  unSubscribeToUsfleetStopsDataStream,
} from './utils';

let loadId: number;
let cannedMessageId: number;
export default defineComponent({
  name: 'App',
  setup() {
    const store = useStore();
    const getSSEVentConnection = computed(() => store.getters.getSSEVentConnection);

    const load = async () => {
      try {
        await store.dispatch(loadStops);
      } catch (err) {
        // handle error
        handleError(err);
      }
    };

    const checkMessages = async () => {
      try {
        await store.dispatch(checkCannedMessages);
      } catch (err) {
        // handle error
        handleError(err);
      }
    };

    onBeforeMount(async () => {
      // subscriptions
      await load();
      await store.dispatch(subscribeToUsfleetStopsDataStream);
      loadId = setInterval(load, 1000 * 60 * 10); //check for updated Craft info every ten minutes
      // canned Messages
      await checkMessages();
      cannedMessageId = setInterval(checkMessages, 1000 * 60); //check for updated Craft info every one minutes
    });

    onBeforeUnmount(() => {
      if (getSSEVentConnection.value) {
        store.dispatch(unSubscribeToUsfleetStopsDataStream);
      }
      clearInterval(loadId);
      clearInterval(cannedMessageId);
    });
  },
});
</script>
<style lang="scss"></style>
