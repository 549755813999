import { ROUTE_ID } from "@/enums";
import { CannedMessage } from "@/types";

export const specificRouteMessages = (routeMessages: CannedMessage[]) => {
  const downtownRoutes = routeMessages?.find(
    (routeMessage: CannedMessage) => routeMessage.routeId === ROUTE_ID.DOWNTOWN
  );

  const bricktownRoutes = routeMessages?.find(
    (routeMessage: CannedMessage) => routeMessage.routeId === ROUTE_ID.BRICKTOWN
  );

  return [bricktownRoutes, downtownRoutes];
};
