import { State } from '@/types';

export const getters = {
  getBricktownDurations: (state: State) => {
    return state.bricktownDurations;
  },
  getCustomBricktownMessage: (state: State) => {
    return state.customBricktownMessage;
  },
  getCustomDowntownMessage: (state: State) => {
    return state.customDowntownMessage;
  },
  getCustomMessageSwitch: (state: State) => {
    return state.customMessage;
  },
  getDowntownDurations: (state: State) => {
    return state.downtownDurations;
  },
  getMessage: (state: State) => {
    return state.alertMessage;
  },
  getSSEVentConnection: (state: State) => {
    return state.usFleetStopsSubscription;
  },
  getStops: (state: State) => {
    return state.stops || [];
  },
  getStop: (state: State) => {
    return state?.stop;
  },
  getStopsHash: (state: State) => {
    return state.stopsHash;
  },
};
