import { __PROD__ } from "./constants";

export const handleError = (err: Error) => {
  if (!__PROD__) {
    //eslint-disable-next-line
    console.log(err);
  } else {
    //eslint-disable-next-line
    console.log('Encountered a problem...');
  }
};
