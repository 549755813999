
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch
} from "vue";
import axios from "axios";
import OverviewRoute from "@/components/OverviewRoute.vue";
import { StopStation, SSEConnection } from "../types";
import {
  handleError,
  loadStops,
  subscribeToUsfleetStopsDataStream,
  unSubscribeToUsfleetStopsDataStream
} from "@/utils/";
import { useStore } from "vuex";
import { ROUTE_ID } from "@/enums";

let loadId: number;
export default defineComponent({
  name: "Overview",
  components: { OverviewRoute },
  setup() {
    const store = useStore();
    const getBricktownDurations = computed(
      () => store.getters.getBricktownDurations
    );
    const getDowntownDurations = computed(
      () => store.getters.getDowntownDurations
    );
    const getMessage = computed(() => store.getters.getMessage);

    const getStops = computed(() => store.getters.getStops);

    const getRouteData = (stopId: number, routeId: number) => {
      if (routeId === ROUTE_ID.BRICKTOWN) {
        return getBricktownDurations.value[stopId];
      } else if (routeId === ROUTE_ID.DOWNTOWN) {
        return getDowntownDurations.value[stopId];
      }
      return null;
    };

    return {
      getRouteData,
      getMessage,
      getStops
    };
  }
});
