
import { computed, defineComponent, PropType, ref } from 'vue';
import { ROUTE_NAME } from '@/enums';
import { Duration } from '@/types';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Route',
  props: {
    routeId: Number,
    routeName: String,
    stopDurationData: Object as PropType<Duration>,
  },
  setup(props) {
    const store = useStore();
    const customBricktownMessage = computed(() => store.getters.getCustomBricktownMessage);
    const customDowntownMessage = computed(() => store.getters.getCustomDowntownMessage);
    const showCustomMessage = computed(() => store.getters.getCustomMessageSwitch);

    const isBricktown = ref(props.routeName?.includes(ROUTE_NAME.BRICKTOWN));
    const isDowntown = ref(props.routeName?.includes(ROUTE_NAME.DOWNTOWN));

    console.log('brick', customBricktownMessage);
    console.log('down', customDowntownMessage);

    return {
      customBricktownMessage,
      customDowntownMessage,
      showCustomMessage,
      isBricktown,
      isDowntown,
    };
  },
});
