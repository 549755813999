<template>
  <div class="overview">
    <div class="messages" v-if="getMessage">{{ getMessage }}</div>
    <div class="stop_table">
      <table
        class="table"
        v-for="stop in getStops"
        :key="stop.uuid"
        :slug="stop.slug"
        :table="true"
        :stop="stop"
      >
        <tr>
          <th class="table__stop-name">{{ stop.stopName }}</th>
          <td>
            <table v-for="route in stop.routes" v-bind:key="route.routeId">
              <tr>
                <th>{{ route.routeName }}</th>
                <OverviewRoute
                  :routeId="route.routeId"
                  :routeDurations="getRouteData(stop.stopId, route.routeId)"
                />
              </tr>
            </table>
          </td>
        </tr>
        <tr v-if="stop.sponsorName">
          <th>Stop Sponsor:</th>
          <td colspan="50">{{ stop.sponsorName }}</td>
        </tr>
        <tr v-if="stop.alertMessage">
          <th>Alert Message:</th>
          <td colspan="50">{{ stop.alertMessage }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch
} from "vue";
import axios from "axios";
import OverviewRoute from "@/components/OverviewRoute.vue";
import { StopStation, SSEConnection } from "../types";
import {
  handleError,
  loadStops,
  subscribeToUsfleetStopsDataStream,
  unSubscribeToUsfleetStopsDataStream
} from "@/utils/";
import { useStore } from "vuex";
import { ROUTE_ID } from "@/enums";

let loadId: number;
export default defineComponent({
  name: "Overview",
  components: { OverviewRoute },
  setup() {
    const store = useStore();
    const getBricktownDurations = computed(
      () => store.getters.getBricktownDurations
    );
    const getDowntownDurations = computed(
      () => store.getters.getDowntownDurations
    );
    const getMessage = computed(() => store.getters.getMessage);

    const getStops = computed(() => store.getters.getStops);

    const getRouteData = (stopId: number, routeId: number) => {
      if (routeId === ROUTE_ID.BRICKTOWN) {
        return getBricktownDurations.value[stopId];
      } else if (routeId === ROUTE_ID.DOWNTOWN) {
        return getDowntownDurations.value[stopId];
      }
      return null;
    };

    return {
      getRouteData,
      getMessage,
      getStops
    };
  }
});
</script>
<style scoped lang="scss"></style>
