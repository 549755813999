<template>
  <table>
    <div>
      <tr v-if="routeDurations?.durations?.length > 0">
        <td v-for="(dur, id) in routeDurations.durations" :key="id">
          {{ dur }} min
        </td>
      </tr>
      <tr v-else>
        <td colspan="3">
          No Service
        </td>
      </tr>
    </div>
  </table>
</template>

<script lang="ts">
import { Duration } from "@/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "Route",
  props: {
    routeId: Number,
    routeDurations: Object as PropType<Duration>,
    table: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style scoped lang="scss">
.routes,
.arrival {
  display: inline-block;
}
.routes {
  float: right;
}
.arrival {
  width: 16.5vw;
  text-align: right;
  opacity: 0.75;
  font-size: 0.875em;
  line-height: 0;
}
.arrival:first-of-type {
  opacity: 1;
  font-size: 1.3em;
}
.noservice {
  width: 49.5vw;
  text-align: right;
}
@media (max-width: 790px) {
  .arrival {
    width: 33%;
    text-align: left;
  }
  .arrival:first-of-type {
    font-size: 0.875em;
  }
  .noservice {
    width: 100%;
    text-align: left;
  }
}
</style>
